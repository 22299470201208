<template>
  <div>
    <BCard>
      <b-row class="justify-content-between mb-[24px]">
        <b-col
          cols="auto"
          class="flex gap-2 items-center"
        >
          <b-button
            variant="primary"
            size="sm"
            class="rounded-lg my-auto"
            @click="$router.push('/grup-contact')"
          >
            <b-icon-chevron-left />
          </b-button>
          <span>
            <h4><strong class="text-black text-2xl">Daftar Kontak / Import Google</strong></h4>
          </span>
        </b-col>
      </b-row>

      <BOverlay
        :show="loading"
        spinner-variant="primary"
      >
        <BTable
          id="table-pelanggan"
          :items="items"
          :fields="fields"
          show-empty
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          sticky-header="700px"
          class="mt-1"
          @scroll.native="handleScroll"
        >
          <template #head(name)="data">
            <span class="items-center flex gap-1">
              <b-form-checkbox
                :checked="allSelected"
                :indeterminate="indeterminate"
                @change="toggleAll"
              />
              {{ data.label }}
            </span>
          </template>
          <template #cell(name)="data">
            <div class="flex gap-1">
              <b-form-checkbox
                :checked="isSelected(data.item)"
                @change="toggleRow(data.item)"
              />
              {{ data.item.name }}
            </div>
          </template>
          <template #cell(phone)="data">
            <div class="flex gap-1">
              <b-img
                src="https://storage.googleapis.com/komerce/assets/icons/whatsapp.svg"
              />
              <div>
                {{ data.item.phone }}
              </div>
            </div>
          </template>
        </BTable>
      </BOverlay>

      <BRow class="mt-1">
        <BCol
          cols="12"
          class="d-flex justify-content-between"
        >
          <div class="d-flex justify-content-center align-items-center gap-[16px]">
            <strong class="text-[#333]"> Total Daftar Kontak </strong>
            <strong class="text-[#F95031]">{{ items.length || 0 }}</strong>
          </div>
          <div>
            <b-button
              :disabled="!selected.length"
              variant="primary"
              @click="handleImport"
            >
              Import
            </b-button>
          </div>
        </BCol>
      </BRow>
    </BCard>
  </div>
</template>

<script>
import { apiGoogleContactList, apiSignInGoogleVerify, apiGoogleContactImport } from '../service/api.groupContact.service'

export default {
  data() {
    return {
      search: '',
      isLoading: false,
      fields: [
        {
          key: 'name', label: 'Nama', thClass: 'font-bold capitalize text-black', sortable: true,
        },
        { key: 'phone', label: 'No. HP', thClass: 'capitalize font-bold' },
      ],
      items: [],
      selected: [],
      token: '',
      loading: false,
      cursor: '',
      limit: 20,
      lastData: false,
    }
  },
  computed: {
    allSelected() {
      return this.selected.length === this.items.length && this.items.length > 0
    },
    indeterminate() {
      return this.selected.length > 0 && this.selected.length < this.items.length
    },
  },
  mounted() {
    this.token = this.$route.query.token
    if (!this.token) {
      this.$router.push('/grup-contact')
    } else {
      this.getListContact(false)
    }
  },
  methods: {
    async getListContact(loadMore = false) {
      if (this.loading || this.lastData) return
      this.loading = true

      try {
        if (!loadMore) {
          this.cursor = ''
          this.items = []
          this.lastData = false
        }
        const { data } = await apiGoogleContactList(this.cursor, this.limit, this.token)
        const items = data.data

        if (loadMore) {
          this.items.push(...items)
        } else {
          this.items = items
        }

        this.cursor = data.meta.pagination.next_cursor_str
        if (items.length < this.limit || !this.cursor) {
          this.lastData = true
        }
      } catch (error) {
        this.$toast_error({ message: 'Gagal menampilkan daftar kontak' })
      } finally {
        this.loading = false
      }
    },
    handleScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        this.getListContact(true)
      }
    },
    isSelected(item) {
      return this.selected.some(selectedItem => selectedItem.name === item.name)
    },
    toggleRow(item) {
      const isCurrentlySelected = this.isSelected(item)
      if (isCurrentlySelected) {
        this.selected = this.selected.filter(selectedItem => selectedItem.name !== item.name)
      } else {
        this.selected.push(item)
      }
    },
    async toggleAll(checked) {
      this.limit = 100
      await this.getListContact()
      this.selected = checked ? [...this.items] : []
    },
    async handleImport() {
      try {
        const contact = this.selected
        await apiGoogleContactImport({ contact })
        this.$router.push('/grup-contact')
        this.$toast_success({ message: 'Kontak berhasil ditambahakan' })
      } catch (err) {
        this.$toast_error({ message: 'Kontak gagal ditambahakan' })
      }
    },
    capitalizeWords(str) {
      if (!str) return str
      return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')
    },
  },
}
</script>

<style scoped>
ul li {
  width: max-content !important;
}
</style>
